<script>
const DEFAULT = 0;
const ERROR = 1;
const SUCCESS = 2;

export default {
  timers: {
    hideMessage: { time: 5000 }
  },
  data() {
    return {
      autohide: true,
      loading: false,
      messageType: DEFAULT,
      message: "",
      showMessage: true,
      update: 0
    };
  },
  methods: {
    reset() {
      this.loading = false;
      this.messageType = DEFAULT;
      this.message = "";
      this.showMessage = true;
    },
    restartTimers() {
      if (this.autohide) {
        this.$timer.stop("hideMessage");
        this.$timer.start("hideMessage");
      }
    },
    beforeRequest() {
      this.reset();
      this.loading = true;
    },
    handleError(message) {
      this.reset();
      this.messageType = ERROR;
      this.showMessage = true;

      this.restartTimers();
      if (typeof message == "object") {
        try {
          console.log(message.response.data.error)
          this.message = message.response.data.error;
        } catch {
          this.message = "Coś poszło nie tak, spróbuj ponownie.";
        }
        
        if (this.message == undefined) {
          this.message = "Coś poszło nie tak, spróbuj ponownie."
        }
      } else if (message == "") {
        this.message = "Coś poszło nie tak, spróbuj ponownie.";
      } else {
        this.message = message;
      }
    },
    handleErrorWith403(error, message=null, onNoAccess=null) {
      if (error.response && error.response.status == 403) {
        if (onNoAccess !== null) {
          onNoAccess();
        }
        this.handleError("Brak dostępu.");
      } else {
        this.handleError(message === null ? error : message);
      }
    },
    handleSuccess(message) {
      this.reset();
      this.messageType = SUCCESS;
      this.showMessage = true;
      this.message = message;

      this.restartTimers();
    },
    hideMessage() {
      this.showMessage = false;
    },
    reloadForm() {
      this.update += 1;
    }
  }
};
</script>
